import { styled } from './../../styles/theme.config.js'

export const Container = styled('div', {
  display        : 'flex',
  justifyContent : 'space-between',
  alignItems     : 'center',
  flexDirection  : 'row',
  width          : '100vw',
  zIndex         : -1,
  padding : '2em 10em',
  '@media only screen and (max-width : 1000px)' :{
        padding : '2em',
        marginTop : '-2em',
        flexDirection : 'column',
    },
  '@media only screen and (max-width : 600px)' :{
        marginTop : '-4em',
    },
})

export const Box = styled('div', {
    display : 'flex',
    marginTop : '3em',
    alignItems : 'center',
    '@media only screen and (max-width : 1000px)' :{
        flexDirection : 'column',
        marginTop : '3em',
      },
})
export const Content = styled('div', {
    display : 'flex',
    flexDirection : 'column',
    justifyContent : 'top',
    alignItems : 'flex-start',
    padding : '5em 1em 0 1em',

    '@media only screen and (max-width : 1000px)' :{
        height : 'initial',
        justifyContent : 'center',
        alignItems : 'center',
        textAlign : 'center'
    },

    '@media only screen and (max-width : 600px)' :{
        alignItems : 'flex-start',
        textAlign: 'left'
        
    },
})

export const Para = styled('p', {
    fontSize : '$3',
    lineHeight : '2em',
    width : '80%',
    '@media only screen and (max-width : 1000px)' :{
        width : '100%',
        fontSize : '$2'
    },
    '@media only screen and (max-width : 600px)' : {
        lineHeight : '1.8em',
    }
})

export const Header = styled('h1', {
    fontFamily   : '$head', 
    fontSize     : '42px',
    lineHeight   : '60px',
    width        : '80%',
    marginBottom : '0.5em',
    '@media only screen and (max-width : 600px)' : {
        fontSize : '28px',
        lineHeight   : '40px',
    }
})

export const Image = styled('img', {
    width : '40%',
    padding : '3em 0 3em 0',
    '@media only screen and (max-width : 1000px)' :{
        width : '80vw',
        padding : '0',
    },
})

export const Button = styled('a', {
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    background     : '$button',
    fontSize       : '$2',
    fontWeight     : '600',
    height         : '3em',
    padding        : '0.5em 1.5em',
    margin         : '3em 0',
    borderRadius   : '4px',
    border         : 'none',
    color          : '$white',
    textDecoration : 'none',
    '&:hover' : {
      color   : '$white',
    }
  })
  