import { Header } from '../Navigation/Navigation.style.js';
import * as S from './Contact.style.js';

const Contact = () => {
    return (
        <S.Container id="contact">
            <S.Content>
                
                <S.Header>Send us an enquiry</S.Header>
                
                <S.Text>We will get back to address your enquiry within 24 hours.</S.Text>
                
                <S.FormContainer
                    autoComplete = "off"
                    method="POST"
                >
                    
                    <S.Row>
                        <S.Box>
                            <S.InputHeading>Your Name *</S.InputHeading>
                            <S.Input 
                                type="text"
                                name="name"
                                required
                            />
                        </S.Box>
                        <S.Box>
                            <S.InputHeading>Company *</S.InputHeading>
                            <S.Input 
                                type="text"
                                name="company"
                                required
                            />
                        </S.Box>
                    </S.Row>

                    <S.Row>
                        <S.Box>
                            <S.InputHeading>Email ID *</S.InputHeading>
                            <S.Input
                                type="email"
                                name="email"
                                required 
                            />
                        </S.Box>
                        <S.Box>
                            <S.InputHeading>Contact number *</S.InputHeading>
                            <S.Input 
                                type="tel"
                                name="contact number"
                                required
                            />
                        </S.Box>
                    </S.Row>
                    
                    <S.Row>
                        <S.Box>
                            <S.InputHeading>Message (Optional)</S.InputHeading>
                            <S.TextArea
                                name="message"
                            />
                        </S.Box>
                    </S.Row>

                    <S.Row>
                        <S.Button
                            as="button"
                            type="submit"
                            value="send"
                        >
                            Submit query</S.Button>
                    </S.Row>

                </S.FormContainer>
                

            </S.Content>            
        </S.Container>
    )
}

export default Contact;