import * as S from './Artisans.style.js'

const Artisans = ({data}) => {
    return (
        <S.Container>
            <S.Header>{data.title}</S.Header>
            <S.Image src={data.image} alt={data.title}/>
            <S.Text>
                India, a land known for its rich culture and heritage is filled with so many vibrant hues that the diversity we have can be beyond our imagination. Most of the handicrafts carry a legacy of a glorious past when these came into existence. These handicraft items are just not mere showpieces or utility items, but they are also a product of sheer hard work and precision that is put in by our super talented and skilled craftsmen. 
            </S.Text>
        </S.Container>
    )
}

export default Artisans;