import * as S from './Sustainability.style.js';

const Sustainability = ({data}) => {
    return (
        <S.Container>
            <S.Header>{data.title}</S.Header>
            <S.Image src={data.image}/>
            <S.Text>
               At Modern Homes, we strive to design and create traditional handcrafted decor and handicraftswith minimal environmental and social impact. We are proud of our exceptional craftsmanship, high quality, and sustainable ways of production. All of our products are made in-house at a facility that has been certified by government agencies to ensure that basic production requirements are satisfied throughout the entire process.
            </S.Text>
        </S.Container>
    )
}

export default Sustainability;